import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import { ConfigContext, GlobalContextProvider } from './context/ConfigContext';
import { SnackbarUtilsConfigurator } from './components/SnackbarUtilsConfigurator';
import { FormDialogServiceProvider } from './components/FormDialog/FormDialogService';
import { ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import blue from '@material-ui/core/colors/blue';
import {
  Apollo as ApolloProvider,
  AuthContext,
  ErrorBoundary,
  useAuthState,
  ServerPublicMetaContextProvider,
  SpinnerContextProvider,
  UserInfoProvider,
} from '@terragotech/gen5-shared-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'utils/theme';
import { CssBaseline } from '@material-ui/core';
import { Router } from 'Router';
import { RedirectServiceProvider } from 'context/RedirectModalContext';
import { ConfirmServiceProvider } from 'context/ConfirmContext';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'fixed',
    inset: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
});

export const App: React.FC = () => {
  const classes = useStyles();
  const authState = useAuthState();
  const { token, clearToken } = authState;
  return (
    <ErrorBoundary>
      <ApolloProvider
        token={token}
        onTokenExpired={clearToken}
        uri={
          process.env.NODE_ENV === 'production'
            ? '/graphql'
            : `${process.env.REACT_APP_SERVER_URL || 'http://localhost:3002'}/graphql`
        }
        onRedirectLogin={() => {
          console.log('Needs login to perform this action');
        }}
      >
        <AuthContext.Provider value={authState}>
          <UserInfoProvider>
            <GlobalContextProvider>
              <ConfigContext.Consumer>
                {(configContext) => {
                  return (
                    <ThemeProvider
                      theme={theme(
                        configContext?.config?.webUIConfig?.theme?.primary || blue[600],
                        configContext?.config?.webUIConfig?.theme?.secondary || blue[600]
                      )}
                    >
                      <CssBaseline />
                      <ServerPublicMetaContextProvider
                        redirectSignInField="redirectConfigEditorSignIn"
                        redirectSignOutField="redirectConfigEditorSignOut"
                        renderLoading={() => (
                          <div className={classes.container}>
                            <CircularProgress color="primary" />
                          </div>
                        )}
                      >
                        <SpinnerContextProvider>
                          {/* @ts-ignore */}
                          <ConfirmProvider>
                            <SnackbarProvider
                              maxSnack={3}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            >
                              <SnackbarUtilsConfigurator />
                              <DndProvider backend={HTML5Backend}>
                                <FormDialogServiceProvider>
                                  <RedirectServiceProvider>
                                    <ConfirmServiceProvider>
                                      <RouterProvider router={Router} />
                                    </ConfirmServiceProvider>
                                  </RedirectServiceProvider>
                                </FormDialogServiceProvider>
                              </DndProvider>
                            </SnackbarProvider>
                          </ConfirmProvider>
                        </SpinnerContextProvider>
                      </ServerPublicMetaContextProvider>
                    </ThemeProvider>
                  );
                }}
              </ConfigContext.Consumer>
            </GlobalContextProvider>
          </UserInfoProvider>
        </AuthContext.Provider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};
