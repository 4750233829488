import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { TextInput } from './TGTextInput';
import { colors } from 'utils/colors';
import { ChangeEvent } from 'react';
import { size } from 'lodash';
import Placeholder from 'common/PlaceHolder';
import { Variant } from 'components/FormElements';
import _ from 'lodash';

interface Props {
  id: string;
  value: string | string[];
  label?: string;
  placeholder?: string;
  variant?: Variant;
  options?: Array<string | number> | Object;
  onChange: (value: string) => void;
  containerStyle?: string | object;
  containerCss?: React.CSSProperties;
  style?: React.CSSProperties;
  type?: string | undefined;
  multiple?: boolean;
  disabled?: boolean;
  canShowEmpty?: boolean;
  selectedValue?: string;
}

export const TGSelect = ({
  id,
  value,
  options,
  onChange,
  label,
  placeholder = '',
  containerStyle,
  containerCss,
  type,
  style,
  variant,
  multiple,
  disabled,
  canShowEmpty = false,
  selectedValue,
}: Props) => {
  const classes = useStyles();

  const handleClearSelection = () => {
    onChange('');
  };
  return (
    <FormControl
      className={`${classes.formControl} ${containerStyle ?? ''}`}
      style={containerCss}
      variant={variant ? variant : 'outlined'}
    >
      {size(label) > 0 && (
        <InputLabel shrink htmlFor={id} className={classes.inputLabel}>
          {label}
        </InputLabel>
      )}
      <Select
        id={id}
        displayEmpty
        disabled={disabled}
        value={selectedValue || value}
        renderValue={() => {
          const displayValue = value ? value : `Select ${type || 'type'}`;
          return _.isEmpty(value) ? <Placeholder>{displayValue}</Placeholder> : <>{displayValue}</>;
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        onChange={(
          e: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
          }>
        ) => {
          if (e?.target?.value === 'clear') {
            handleClearSelection();
          } else {
            onChange((e?.target?.value as string) || '');
          }
        }}
        input={<TextInput placeholder={placeholder} multiline={multiple} />}
        className={classes.selectTxt}
        style={style}
      >
        {(!_.isEmpty(options) &&
          (_.isArray(options)
            ? [
                canShowEmpty && (
                  <MenuItem key="" value="" className={classes.menuItem}>
                    (None Selected)
                  </MenuItem>
                ),
                ..._.map(options, (option) =>
                  typeof option === 'object' ? (
                    <MenuItem
                      key={option.name as string}
                      value={option.name}
                      className={classes.menuItem}
                    >
                      {option.label}
                    </MenuItem>
                  ) : (
                    <MenuItem key={option as string} value={option} className={classes.menuItem}>
                      {typeof option === 'number' ? `Version ${option}` : option}
                    </MenuItem>
                  )
                ),
              ]
            : [
                canShowEmpty && (
                  <MenuItem key="" value="" className={classes.menuItem}>
                    (None Selected)
                  </MenuItem>
                ),
                ..._.map(options, (value, key) => (
                  <MenuItem
                    key={(value as unknown) as string}
                    value={(value as unknown) as string}
                    className={classes.menuItem}
                  >
                    {key}
                  </MenuItem>
                )),
              ])) || (
          <MenuItem value="" disabled>
            <em>None Available</em>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black,
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, -3px) scale(1)',
      },
    },
    inputFieldContainer: {
      display: 'flex',
      width: '100%',
      marginBottom: 20,
    },
    formControl: {
      width: '100%',
      '& .MuiSelect-root': {
        fontSize: 15,
        color: colors.black,
      },
    },
    menuItem: {
      color: colors.black,
      fontSize: 15,
      width: '98%',
      margin: 'auto',
      borderRadius: 5,
    },
    selectTxt: {
      '& .MuiSelect-select:focus': {
        borderRadius: 5,
      },
      '& .MuiSelect-select.MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  })
);
