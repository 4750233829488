import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import { DiagramEngine, DagreEngine } from '@projectstorm/react-diagrams';
import DropLayer from './DropLayer/DropLayer';
import MenuManager from './MenuManager/MenuManager';
import { CanvasBackground } from './Components/CanvasBackground';
import {
  MapScenarioType,
  NodeMapDefinition,
  mapScenarios,
} from '@terragotech/gen5-datamapping-lib';
import { colors } from 'utils/colors';
import { Box, makeStyles } from '@material-ui/core';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION } from 'utils/Utils';
import { EventMapDiagramModel } from './EventMapDiagramModel';
import _ from 'lodash';

function useForceUpdate() {
  const [, set] = useState(true); // boolean state
  return () => set((value) => !value); // toggle the state to force render
}
interface EventMapDiagram {
  mapScenario?: keyof MapScenarioType;
  model?: EventMapDiagramModel;
  engine: DiagramEngine;
  hasSetPosition?: boolean;
  setInitialDataMap: Dispatch<SetStateAction<NodeMapDefinition | undefined>>;
}

const dagreEngine = new DagreEngine({
  graph: {
    rankdir: 'LR',
    align: 'DR',
    ranker: 'longest-path',
    marginx: 50,
    marginy: 50,
    nodesep: 100,
    ranksep: 150,
  },
  includeLinks: false,
});
export const EventMapDiagram: FunctionComponent<EventMapDiagram> = ({
  engine,
  model,
  mapScenario,
  hasSetPosition,
  setInitialDataMap,
}) => {
  // on first render, we want to wait for one full draw and then distribute all nodes
  const classes = useStyles();
  const firstRender = useRef(true);
  const { openConfirmation } = useConfirmDialog();
  const [shownMissingNodeAlert, setShownMissingNodeAlert] = useState<boolean>(false);
  useEffect(() => {
    if (firstRender.current && model && engine) {
      firstRender.current = false;
      setTimeout(() => {
        if (!hasSetPosition) {
          dagreEngine.redistribute(model);
        }
        engine.zoomToFit();
        engine.repaintCanvas();
        setInitialDataMap(_.cloneDeep(model.getMapDefinition()));
      }, 0);
    }
  }, [model, engine, hasSetPosition, setInitialDataMap]);

  const forceUpdate = useForceUpdate();

  const createMissingNodes = useCallback(
    async (creatableNodes: Array<{ nodeId: string }>) => {
      const props = CONFIRMATION.mapperDiagram;
      const status = await openConfirmation(props);
      if (mapScenario && status === 'confirm') {
        const scenario = mapScenarios[mapScenario];
        creatableNodes.forEach((creatableNode, index) => {
          const node = (scenario.defaultNodeMap.nodes as any)[creatableNode.nodeId];
          if (engine) {
            const nodeFactory = engine.getNodeFactories().getFactory(node.type);
            //const nodeFactory = basicNodeFactories[node.type];
            if (nodeFactory && model) {
              const transformNodeModel = nodeFactory.generateModel({
                initialConfig: { ...node, id: creatableNode.nodeId },
              });
              transformNodeModel.setPosition(350, index * 150 + 50);
              model.addNode(transformNodeModel);
            } else {
              console.error(`Transform type does not exist: ${node.type}`);
            }
          }
        });
      }
    },
    [engine, mapScenario, model, openConfirmation]
  );

  useEffect(() => {
    // This logic is used to verify that all required nodes are present when working on the map
    if (model && mapScenario) {
      const scenario = mapScenarios[mapScenario];
      if (scenario) {
        const existingNodes = model.getNodes();
        const missingNodes = scenario.requiredNodes.filter((node) => {
          return (
            existingNodes.findIndex((existingNode) => existingNode.getID() === node.nodeId) < 0
          );
        });
        if (missingNodes.length > 0) {
          //If the node exists in the default node map, add to creatable Node list
          const creatableNodes = missingNodes.filter(
            (node) => !!(scenario.defaultNodeMap.nodes as any)[node.nodeId]
          );
          if (creatableNodes.length > 0 && !shownMissingNodeAlert) {
            setShownMissingNodeAlert(true);
            createMissingNodes(creatableNodes);
          }
        } else {
          setShownMissingNodeAlert(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [model, engine, mapScenario, createMissingNodes]); // Since updating shownMissingNodeAlert state inside this useEffect, not added it in dependencies to avoid Re-Rendering
  return (
    <Box className={classes.container}>
      {model && (
        <MenuManager diagramEngine={engine} mapScenario={mapScenario}>
          <DropLayer diagramEngine={engine} onDroppedNode={forceUpdate}>
            <CanvasBackground background={colors.lotion}>
              <CanvasWidget engine={engine} />
            </CanvasBackground>
          </DropLayer>
        </MenuManager>
      )}
    </Box>
  );
};

const defaultArea = {
  width: '100%',
  height: '100%',
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    '& svg[class^="css"]': {
      ...defaultArea,
      overflow: 'visible',
      '& + div': defaultArea,
    },
  },
}));
