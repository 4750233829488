import { Box, Typography } from '@material-ui/core';
import { MapperList } from 'components/FormElements';
import { useStyles } from './useStyles';
import { Mapper } from './MapperDefinition';

interface Props {
  mapperList: Mapper[];
  containerStyle?: string;
}

const FormValidation = ({ mapperList, containerStyle = '' }: Props) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.itemContainer} ${classes.bottom0} ${containerStyle}`}>
      <Typography className={classes.label}>Form Validation</Typography>
      {mapperList.map((mapper) => (
        <MapperList {...mapper} />
      ))}
    </Box>
  );
};

export default FormValidation;
