import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Modal,
  Box,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  FormControlLabel,
  Radio,
  Button,
  RadioGroup,
  IconButton,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { categoryColors, colors } from 'utils/colors';
import previewBgImage from '../../../../resources/images/backgroundLabels.png';
import {
  CIRCLE,
  useSymbolValue,
  NODEONLY,
  DEFAULT_SYMBOL_KEY,
  DEFAULT_SYMBOL_SCALE,
  SYMBOL_TEXT_GLYPH,
  SYMBOL_UNICODE,
  UNICODE_PREFIX,
} from '../SymbolContext';
import { errorMsg } from 'components/SnackbarUtilsConfigurator';
import { TGColorPicker, TextInputTG } from 'views/components/formElements';
import { getSvgImageString } from '@terragotech/svg-symbol-lib';
import { SYMBOL_OPTION } from '../../../../utils/Utils';
interface modalProps {
  open: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddSymbol: (symbol: object) => void;
  handleUpdateSymbol: (symbol: object, oldSymbol: object) => void;
  selectedSymbolName?: string;
  selectedSymbolKey?: string;
}
const MAIN_MODAL = 120;
const INNER_CONTAINER = 136;
const SymbolEditModal: React.FC<modalProps> = (props) => {
  const {
    selectedSymbol,
    selectedSymbolValues,
    setSelectedSymbol,
    symbolName,
    setSymbolName,
    symbolKey,
    setSymbolKey,
    symbol,
    setSymbol,
    symbolColor,
    setSymbolColor,
    alphaNumericCharacter,
    setAlphaNumericCharacter,
    symbolScale,
    setSymbolScale,
  } = useSymbolValue();
  const { open, setModal, handleAddSymbol, handleUpdateSymbol } = props;
  const [symbolTextOnly, setSymbolTextOnly] = useState(true);
  useEffect(() => {
    if (selectedSymbolValues?.symbol && selectedSymbolValues?.character) {
      setSymbolTextOnly(
        selectedSymbolValues.symbol !== NODEONLY &&
          selectedSymbolValues.character.startsWith(UNICODE_PREFIX)
          ? false
          : true
      );
    }
  }, [selectedSymbolValues]);
  const classes = useStyles();
  const handleModalClose = () => {
    setModal(false);
    setSelectedSymbol(null);
    setSymbolName('');
    setSymbolColor(colors.black);
    setAlphaNumericCharacter('');
    setSymbolKey(DEFAULT_SYMBOL_KEY);
    setSymbolScale(DEFAULT_SYMBOL_SCALE);
  };
  const isSymbolInvalid = () => {
    if (symbolName.trim() === '') {
      errorMsg('Property "Name" is required');
      return true;
    }
    if (symbolColor.trim() === '') {
      errorMsg('Property "Symbol Color" is required');
      return true;
    }
    if (symbol !== NODEONLY && alphaNumericCharacter.trim() === '') {
      errorMsg('Property "Symbol Text/Unicode/Glyph" is required');
      return true;
    }
    if (symbol !== NODEONLY && symbolScale.trim() === '') {
      errorMsg('Property "Symbol Scale" is required');
      return true;
    }

    return false;
  };
  const handleSave = () => {
    if (isSymbolInvalid()) {
      return;
    }
    let updatedSymbol = {
      name: symbolName,
      symbolKey,
    };
    if (selectedSymbol) {
      handleUpdateSymbol(updatedSymbol, selectedSymbol);
      handleModalClose();
      return;
    }
    handleAddSymbol(updatedSymbol);
    handleModalClose();

    return;
  };
  const handleSymbolChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSymbol = e.target.value;
    const [symbolValue, SymbolFormat] = updatedSymbol.split('_');
    setSymbol(symbolValue);
    if (SymbolFormat) {
      setSymbolTextOnly(SymbolFormat === SYMBOL_TEXT_GLYPH ? true : false);
      setAlphaNumericCharacter('');
    }
    setSymbolKey(
      'symbol_' + symbolValue + '_' + symbolColor.replace('#', '') + '_' + '' + '_' + symbolScale
    );
  };
  const handleColorChange = (updatedColor: string) => {
    setSymbolColor(updatedColor);
    setSymbolKey(
      'symbol_' +
        symbol +
        '_' +
        updatedColor.replace('#', '') +
        '_' +
        `${symbol === NODEONLY ? '' : getUnicode(alphaNumericCharacter)}` +
        '_' +
        symbolScale
    );
  };
  const getUnicode = (value: string) => {
    const r = /\\u([\d\w]{4})/gi;
    if (value && !symbolTextOnly) {
      value = UNICODE_PREFIX + value;

      value = value.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
      value = decodeURIComponent(value);
    }
    return value;
  };
  const handleCharacterChange = (updatedCharacter: string) => {
    setAlphaNumericCharacter(updatedCharacter);
    setSymbolKey(
      'symbol_' +
        symbol +
        '_' +
        symbolColor.replace('#', '') +
        '_' +
        `${getUnicode(updatedCharacter)}` +
        '_' +
        symbolScale
    );
  };
  const handleScaleChange = (updatedScaleValue: string) => {
    setSymbolScale(updatedScaleValue);
    setSymbolKey(
      'symbol_' +
        symbol +
        '_' +
        symbolColor.replace('#', '') +
        '_' +
        `${getUnicode(alphaNumericCharacter)}` +
        '_' +
        updatedScaleValue
    );
  };
  const getCurrentSymbol = () => {
    if (symbol === NODEONLY) {
      return symbol;
    }
    const selectedSymbolOption = symbolTextOnly ? SYMBOL_TEXT_GLYPH : SYMBOL_UNICODE;
    return symbol + `_${selectedSymbolOption}`;
  };

  const isNodeOnly = symbol === NODEONLY;
  return (
    <Modal
      open={open}
      onClose={() => {
        handleModalClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.rootContainer}
    >
      <Box className={classes.modalContainer}>
        <Box className={classes.modalHeader}>
          <Typography variant="h6" component="h2">
            {selectedSymbol ? 'Edit' : 'Add'}
          </Typography>
          <IconButton onClick={() => handleModalClose()}>
            <FontAwesomeIcon icon={faXmark} className={classes.closeIcon} />
          </IconButton>
        </Box>
        <Box className={classes.innerContainer}>
          <Typography className={[classes.colorPickerLabel, classes.previewTitle].join(' ')}>
            Preview
          </Typography>

          <Box className={classes.previewContainer}>
            <img
              src={getSvgImageString(symbolKey, SYMBOL_OPTION)}
              alt="symbol key"
              className={classes.previewIconStyle}
            />
          </Box>
          <TextInputTG
            id="symbolName"
            value={symbolName}
            onChange={setSymbolName}
            placeholder="Enter Name"
            label="Name"
            inputRoot={classes.customInputRoot}
            labelRoot={classes.colorPickerLabel}
            containerStyle={classes.textInputContainer}
          />
          <TGColorPicker
            title={'Symbol Color'}
            value={symbolColor}
            onChange={handleColorChange}
            classes={{ label: classes.colorPickerLabel, input: classes.pickerContainer }}
          />
          <Typography className={[classes.colorPickerLabel, classes.inputLabel].join(' ')}>
            Symbol Type
          </Typography>
          <RadioGroup value={getCurrentSymbol()} onChange={handleSymbolChange}>
            <FormControlLabel
              value={NODEONLY}
              control={
                <Radio
                  color="primary"
                  className={symbol === NODEONLY ? classes.activeRadioButton : classes.radioButton}
                />
              }
              label={<Typography className={classes.optionLabel}>Node Only</Typography>}
              className={[classes.firstElement, classes.secondElement].join(' ')}
            />
            <FormControlLabel
              value={
                (selectedSymbolValues.symbol !== NODEONLY
                  ? selectedSymbolValues.symbol || CIRCLE
                  : CIRCLE) + `_${symbolTextOnly ? SYMBOL_TEXT_GLYPH : SYMBOL_UNICODE}`
              }
              control={
                <Radio
                  color="primary"
                  className={
                    symbol !== NODEONLY && symbolTextOnly
                      ? classes.activeRadioButton
                      : classes.radioButton
                  }
                />
              }
              label={
                <Typography className={classes.optionLabel}>Symbol Text/Unicode/Glyph</Typography>
              }
              className={classes.secondElement}
            />
            {!isNodeOnly && (
              <TextInputTG
                fullWidth
                className={classes.symbolInput}
                value={alphaNumericCharacter}
                onChange={handleCharacterChange}
                inputRoot={classes.customInputRoot}
                containerStyle={classes.symbolTextContianer}
                placeholder=" "
              />
            )}
          </RadioGroup>
          {!isNodeOnly && (
            <>
              <Typography className={[classes.optionLabel, classes.scaleLabel].join(' ')}>
                Symbol Scale
              </Typography>
              <TextInputTG
                fullWidth
                type="number"
                onScroll={(e) => e.preventDefault()}
                onWheel={(e) => e.preventDefault()}
                className={classes.symbolInput}
                value={symbolScale}
                onChange={handleScaleChange}
                inputRoot={classes.customInputRoot}
                InputProps={{
                  onScroll: (e) => e.currentTarget.blur(),
                  onWheel: (e) => e.preventDefault(),
                }}
                placeholder=" "
              />
            </>
          )}
        </Box>
        <Box className={classes.btnContainer}>
          <Button
            variant="outlined"
            color="primary"
            className={`${classes.button} ${classes.cancelButton}`}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.button} ${classes.saveButton}`}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      backdropFilter: 'blur(1px)',
    },
    firstElement: {
      marginBottom: 15,
    },
    secondElement: {
      height: 20,
    },
    modalContainer: {
      width: 492,
      margin: 'auto',
      height: `calc(100% - ${MAIN_MODAL}px)`,
      backgroundColor: colors.white,
      borderRadius: '5px',
      boxShadow: `0px 4px 6px 4px ${colors.black10}`,
      marginTop: 80,
    },
    modalHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '21px 16px 20px 29px',
      height: '61px',
      borderBottom: `1px solid ${colors.black10}`,
    },
    innerContainer: {
      padding: '21px 26px',
      overflow: 'auto',
      height: `calc(100% - ${INNER_CONTAINER}px)`,
    },
    textInput: {
      height: 36,
      marginBottom: 12,
      borderRadius: 5,
      '& .MuiInputBase-input': {
        border: `0.5px solid ${categoryColors.defaultColor}`,
      },
    },
    customInputRoot: {
      '& fieldset': {
        border: `0.5px solid ${categoryColors.defaultColor}`,
      },
    },
    textInputContainer: {
      marginBottom: 28,
    },
    symbolTextContianer: {
      marginBottom: 22,
    },
    inputContainer: {
      height: 36,
      marginBottom: 12,
      borderRadius: 5,
      border: `0.5px solid ${categoryColors.defaultColor}`,
      paddingInline: 14,
    },
    disabled: {
      backgroundColor: colors.greyBackground,
    },
    previewContainer: {
      background: `url(${previewBgImage}),lightgray -0.22px 0px / 122.115% 100% no-repeat`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '130px',
      borderRadius: 5,
      overflow: 'hidden',
      flexShrink: 0,
      marginBottom: 20,
    },
    previewTitle: {
      color: colors.black,
      marginBottom: 5,
    },
    colorPickerLabel: {
      fontSize: '15px',
      fontWeight: 500,
    },
    pickerContainer: {
      '& .MuiInputBase-root fieldset': {
        border: `0.5px solid ${categoryColors.defaultColor}`,
      },
    },
    inputLabel: {
      color: colors.black,
      marginBottom: 23,
      marginTop: 32,
      lineHeight: '100%',
    },
    radioBtnLabel: {
      fontSize: '15px',
      fontWeight: 400,
      color: colors.black,
    },
    symbolInput: {
      marginTop: 8,
      marginLeft: 30,
      width: '93%',
    },
    closeIcon: {
      width: 24,
      height: 24,
      color: colors.black54,
      padding: 0,
    },
    btnContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '14px 23px 21px',
      borderTop: `1px solid ${colors.gray10}`,
    },
    button: {
      width: 212,
      height: 40,
      fontSize: 18,
      fontWeight: 500,
      borderRadius: 5,
    },
    cancelButton: {
      borderWidth: 1.5,
    },
    saveButton: {
      boxShadow: 'none',
    },
    optionLabel: {
      fontSize: '14px',
      fontWeight: 400,
    },
    scaleLabel: {
      marginLeft: 30,
    },
    colorInput: {
      paddingLeft: 0,
      height: 40,
      marginBottom: 12,
    },
    radioButton: {
      color: colors.black54,
    },
    activeRadioButton: {
      color: theme.palette.primary.main,
    },
    previewIconStyle: {
      width: 35,
      height: 35,
      borderRadius: 35 / 2,
      backgroundColor: colors.white,
      boxShadow: `0px 4px 4px 0px ${colors.black25}`,
    },
  })
);
export default SymbolEditModal;
