import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
  MouseEvent,
  useEffect,
  useRef,
} from 'react';
import {
  Button,
  FormControlLabel,
  ListItemIcon,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  Typography,
  Box,
} from '@material-ui/core';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import styledComp from 'styled-components';
import { useBlocker, useParams } from 'react-router-dom';
import { ConfigContext } from '../../context/ConfigContext';
import { successMsg } from '../SnackbarUtilsConfigurator';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { WebAggrUICustomization, MobileAggrUICustomization, UIConfigType } from '../../utils/types';
import PageHeader from 'views/components/PageHeader';
import { Theme, createStyles, makeStyles, styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TRow from '@material-ui/core/TableRow';
import { faBars, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'utils/colors';
import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons';
import { CONFIRMATION, configType } from 'utils/Utils';
import useCommonStyles from 'views/useCommonStyles';
import AccordionCard from 'components/AccordionCard';
import useRouteBlocker from 'common/useBlocker';
import { size } from 'lodash';
import { useConfirmDialog } from 'context/ConfirmContext';
import _ from 'lodash';

const EXTRA_PADDING_BOTTOM = 20;
const paperPropsStyle = (bottom: number) => ({
  maxHeight: `calc(100% - ${bottom + EXTRA_PADDING_BOTTOM}px)`,
  minWidth: 245,
  maxWidth: 300,
});

const TableCell = styled(TCell)({
  padding: '7px 16px',
  height: 55,
  borderBottom: `1px solid ${colors.black10}`,
});

const TableRow = styled(TRow)({
  backgroundColor: colors.white1,
  '&:last-child td.MuiTableCell-root': {
    borderBottom: 'none',
  },
});

const DEFAULT_DROPDOWN_CLIENT_BOTTOM = 450;
export const MapLabels: React.FC = () => {
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const commonClasses = useCommonStyles();
  const {
    getUICustomization,
    setUICustomization,
    getAggregates,
    getAggregateProperties,
    configDetails,
  } = useContext(ConfigContext);
  const aggregateList = getAggregates();
  const { aggrUICustomization } = useParams() as { aggrUICustomization: string };
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [clientBottom, setClientBottom] = useState<number>(DEFAULT_DROPDOWN_CLIENT_BOTTOM);
  const open = Boolean(anchorEl);

  const getPosition = () => {
    if (dropdownRef.current) {
      const client = dropdownRef.current.getBoundingClientRect();
      setClientBottom(client?.bottom || DEFAULT_DROPDOWN_CLIENT_BOTTOM);
    }
  };

  useEffect(() => {
    getPosition();
    document.addEventListener('scroll', getPosition, true);
    return () => document.removeEventListener('scroll', getPosition, true);
  }, []);

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };
  const [isDirty, setIsDirty] = useState(false);
  const { openConfirmation } = useConfirmDialog();
  const KEY = `${aggrUICustomization}.mapLabelProperties`;
  const aggregateIndex = aggregateList.findIndex(
    (aggregateType) => aggregateType.typeName === aggrUICustomization
  );
  const aggregateObject = useMemo(() => getAggregateProperties(aggregateIndex), [
    aggregateIndex,
    getAggregateProperties,
  ]);
  const aggregateProperties = useMemo(
    () =>
      Object.keys(aggregateObject).filter((aggProp) => aggregateObject[aggProp].label !== 'none'),
    [aggregateObject]
  );

  const [currentCustomizations, setCurrentCustomizations] = useState(
    getUICustomization(
      (configDetails[KEY]?.config as UIConfigType) || 'mobileUIConfig',
      aggrUICustomization
    )
  );

  const selectedConfig = configDetails[KEY];

  const initialDropDownItems = aggregateProperties.filter(
    (aggProp) => !currentCustomizations?.mapLabelProperties?.includes(aggProp)
  );
  const initialMenuItems = currentCustomizations?.mapLabelProperties
    ? currentCustomizations.mapLabelProperties.filter((aggProp) =>
        aggregateProperties.includes(aggProp)
      )
    : [];
  const [dropDownItems, setDropDownItems] = useState([...initialDropDownItems]);
  const [menuItems, setMenuItems] = useState([...initialMenuItems]);

  const [showLabels, setShowLabels] = useState<boolean>(
    currentCustomizations?.showMapLabelTitles === null ||
      currentCustomizations?.showMapLabelTitles === undefined
      ? true
      : currentCustomizations.showMapLabelTitles
  );

  const [showEmptyLabels, setShowEmptyLabels] = useState<boolean>(
    currentCustomizations?.showEmptyMapLabels === null ||
      currentCustomizations?.showEmptyMapLabels === undefined
      ? true
      : currentCustomizations.showEmptyMapLabels
  );
  const mapLabelProperties = useMemo(() => {
    return currentCustomizations?.mapLabelProperties;
  }, [currentCustomizations]);
  const updateMenuItems = useCallback(() => {
    if (size(mapLabelProperties) > 0 && mapLabelProperties) {
      const menus = mapLabelProperties?.filter((aggProp) => aggregateProperties.includes(aggProp));
      setMenuItems(menus);
    } else {
      setMenuItems([]);
    }
  }, [mapLabelProperties, aggregateProperties]);
  useEffect(() => {
    setShowLabels(currentCustomizations?.showMapLabelTitles as boolean);
    setShowEmptyLabels(currentCustomizations?.showEmptyMapLabels as boolean);
  }, [currentCustomizations]);
  useEffect(() => {
    updateMenuItems();
  }, [mapLabelProperties, updateMenuItems]);

  const handleSave = useCallback(() => {
    if (selectedConfig?.type === configType.ALL.type) {
      setUICustomization('webUIConfig', aggrUICustomization, {
        ...(currentCustomizations as WebAggrUICustomization),
        mapLabelProperties: menuItems,
        showMapLabelTitles: showLabels,
        showEmptyMapLabels: showEmptyLabels,
      });

      setUICustomization('mobileUIConfig', aggrUICustomization, {
        ...(currentCustomizations as MobileAggrUICustomization),
        mapLabelProperties: menuItems,
        showMapLabelTitles: showLabels,
        showEmptyMapLabels: showEmptyLabels,
      });
    } else {
      setUICustomization(selectedConfig.config as UIConfigType, aggrUICustomization, {
        ...(currentCustomizations as MobileAggrUICustomization),
        mapLabelProperties: menuItems,
        showMapLabelTitles: showLabels,
        showEmptyMapLabels: showEmptyLabels,
      });
    }
    setIsDirty(false);
    successMsg('Map Label Properties saved.');
  }, [
    aggrUICustomization,
    currentCustomizations,
    setUICustomization,
    menuItems,
    showLabels,
    showEmptyLabels,
    selectedConfig,
  ]);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && isDirty
  );
  useRouteBlocker({ blocker, onSave: handleSave });

  //Moves to destination array, removes from origin array
  const handleArrayToArray = (
    index: number,
    originArray: string[],
    setOriginArray: (array: string[]) => void,
    destinationArray: string[],
    setDestinationArray: (array: string[]) => void,
    unShift?: boolean
  ) => {
    const tempDestinationArray = [...destinationArray];
    unShift
      ? tempDestinationArray.unshift(originArray[index])
      : tempDestinationArray.push(originArray[index]);
    setDestinationArray(tempDestinationArray);
    const tempOriginArray = [...originArray];
    tempOriginArray.splice(index, 1);
    setOriginArray(tempOriginArray);
    setIsDirty(true);
  };

  useEffect(() => {
    if (selectedConfig?.type) {
      setCurrentCustomizations(
        getUICustomization(
          (selectedConfig.config as UIConfigType) || 'mobileUIConfig',
          aggrUICustomization
        )
      );
    }
  }, [selectedConfig, aggrUICustomization, getUICustomization]);

  const dropDownItemsMapped = useMemo(() => {
    return (
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{ paper: classes.menuRoot }}
        elevation={4}
        PaperProps={{ style: paperPropsStyle(clientBottom) }}
      >
        {dropDownItems.map((item, index) => {
          return (
            <MenuItem
              style={{ ...styles.menuItem1 }}
              className={`${classes.commontxt} ${classes.menuItem}`}
              onClick={() =>
                handleArrayToArray(index, dropDownItems, setDropDownItems, menuItems, setMenuItems)
              }
              key={index}
            >
              {aggregateObject[item]?.label}
            </MenuItem>
          );
        })}
      </Menu>
    );
  }, [
    dropDownItems,
    menuItems,
    aggregateObject,
    open,
    anchorEl,
    classes.commontxt,
    classes.menuItem,
    classes.menuRoot,
    clientBottom,
  ]);

  const handleDeleteItem = useCallback(
    async (index: number, data: { label: string }) => {
      const props = CONFIRMATION.commonDelete({ name: data.label });
      const status = await openConfirmation(props);
      if (status === 'confirm') {
        handleArrayToArray(index, menuItems, setMenuItems, dropDownItems, setDropDownItems, true);
      }
    },
    [openConfirmation, menuItems, dropDownItems]
  );

  const menuItemsMapped = useMemo(() => {
    function onDragEnd(result: DropResult) {
      if (!result.destination) {
        return;
      }
      const newItems = [...menuItems];
      const [removed] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, removed);
      setMenuItems(newItems);
      setIsDirty(true);
    }
    const columnHeaders = ['Actions', ' Item Key', 'Item Label', 'Type'];
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="Arbitrary">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <TableContainer className={classes.tableContainer}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow style={styles.tableHeader}>
                      {_.map(columnHeaders, (item, index) => {
                        return (
                          <>
                            <TableCell
                              className={`${classes.commontxt} ${classes.tableHeader} ${
                                index === 0 ? classes.firstCell : ''
                              }`}
                            >
                              {item}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!_.isEmpty(menuItems) ? (
                      menuItems.map((item, index) => (
                        <Draggable draggableId={item} key={item} index={index}>
                          {(provided) => (
                            <TableRow
                              key={index}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                              }}
                            >
                              <TableCell>
                                <ListItemIcon>
                                  <IconButton edge="end" {...provided.dragHandleProps}>
                                    <FontAwesomeIcon icon={faBars} style={styles.faIcon} />
                                  </IconButton>
                                  <IconButton
                                    edge="end"
                                    className={classes.trashButton}
                                    onClick={() => {
                                      handleDeleteItem(index, aggregateObject[item]);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashCan} style={styles.faIcon} />
                                  </IconButton>
                                </ListItemIcon>
                              </TableCell>
                              <TableCell className={`${classes.commontxt} ${classes.tableCell}`}>
                                {item}
                              </TableCell>
                              <TableCell className={`${classes.commontxt} ${classes.tableCell}`}>
                                {aggregateObject[item]?.label}
                              </TableCell>
                              <TableCell className={`${classes.commontxt} ${classes.tableCell}`}>
                                {aggregateObject[item]?.type}
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={columnHeaders.length}>
                          <Typography className={[classes.emptyLabel, classes.title].join(' ')}>
                            No records to display
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }, [
    menuItems,
    aggregateObject,
    classes.commontxt,
    classes.tableHeader,
    classes.firstCell,
    classes.tableCell,
    classes.tableContainer,
    classes.trashButton,
    classes.emptyLabel,
    classes.title,
    handleDeleteItem,
  ]);

  const showMapLabelGroup = useMemo(() => {
    return (
      <div className={classes.root}>
        <AccordionCard header="Options">
          <FormControlLabel
            style={{ ...styles.formControlLabel }}
            className={`${classes.commontxt} ${classes.checkbox}`}
            control={
              <Checkbox
                checked={showLabels}
                onChange={() => {
                  setShowLabels((prevState) => !prevState);
                  setIsDirty(true);
                }}
                color="primary"
              />
            }
            label="Show labels for each attribute in the map label"
          />
          <FormControlLabel
            style={{ ...styles.formControlLabel }}
            className={`${classes.commontxt} ${classes.checkbox}`}
            control={
              <Checkbox
                checked={showEmptyLabels}
                onChange={() => {
                  setShowEmptyLabels((prevState) => !prevState);
                  setIsDirty(true);
                }}
                color="primary"
              />
            }
            label="Show attributes with blank values"
          />
        </AccordionCard>
      </div>
    );
  }, [showLabels, showEmptyLabels, classes.commontxt, classes.root, classes.checkbox]);

  return (
    <Box className={commonClasses.innerContainer}>
      <PageHeader
        title="Map Labels"
        onSave={handleSave}
        buttonText="Save"
        icon={faFloppyDisk}
        simulate={false}
        configKey={KEY}
      />
      {showMapLabelGroup}
      <OptionsContainer>
        <Typography className={`${classes.txt} ${classes.optionTitle}`}>Attributes</Typography>
        <DropDownContainer>
          <Button
            ref={dropdownRef}
            variant="outlined"
            endIcon={
              <KeyboardArrowDownIcon
                className={`${classes.dropdownArrow} ${Boolean(anchorEl) && classes.activeArrow}`}
              />
            }
            onClick={handleClick}
            className={`${classes.addButton}`}
            color="primary"
          >
            Add Attribute to Label
          </Button>
          {dropDownItemsMapped}
        </DropDownContainer>
      </OptionsContainer>
      <MenuItemContainer>{menuItemsMapped}</MenuItemContainer>
    </Box>
  );
};
const styles: { [key: string]: React.CSSProperties } = {
  formControlLabel: {
    width: '90%',
    position: 'relative',
    right: 5,
  },
  accordianDetails: {
    display: 'flex' as const,
    flexDirection: 'column' as const,
  },
  menuItem1: {
    textOverflow: 'ellipsis',
  },
  faIcon: {
    height: 18,
    width: 18,
  },
  tableHeader: {
    background: colors.grayBackground,
    height: 45,
  },
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    tableContainer: {
      border: `1px solid ${colors.black5}`,
      borderRadius: 5,
    },
    btn: {
      margin: '10px 0px',
      borderRadius: 5,
      border: `1px solid ${colors.blueBorder}`,
      boxShadow: `0px 2px 4px 0px ${colors.black10}`,
      backgroundColor: colors.white,
    },
    commontxt: {
      fontStyle: 'normal',
      color: colors.black,
      lineHeight: '100%',
    },
    txt: {
      fontWeight: 500,
      fontSize: 18,
    },
    optionTitle: {
      fontSize: 21,
      color: colors.black,
      lineHeight: '100%',
    },
    emptyLabel: {
      textAlign: 'center',
    },
    title: {
      fontWeight: 400,
      fontSize: 14,
    },
    tableHeader: {
      fontWeight: 500,
      fontSize: 15,
      padding: '10px 16px',
    },
    tableCell: {
      fontSize: 15,
      fontWeight: 400,
    },
    checkbox: {
      fontWeight: 400,
      paddingLeft: 4,
      '& .MuiTypography-body1': {
        fontSize: 15,
      },
    },
    firstCell: {
      paddingLeft: 28,
    },
    addButton: {
      fontSize: 16,
      fontWeight: 500,
      padding: '9px 9px 9px 26px',
      height: 40,
      width: 242,
    },
    trashButton: {
      marginLeft: 5,
    },
    dropdownArrow: {
      width: 24,
      height: 24,
      transform: 'rotate(0deg)',
      transition: 'transform 300ms',
    },
    activeArrow: {
      transform: 'rotate(180deg)',
    },
    menuItem: {
      fontSize: 15,
      fontWeight: 400,
    },
    emptyOptionContainer: {
      marginTop: '14px !important',
    },
    menuRoot: {
      marginTop: 5,
    },
  })
);
const OptionsContainer = styledComp('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 29,
});
const DropDownContainer = styledComp('div')({
  margin: '10px 0px',
});
const MenuItemContainer = styledComp('div')({
  position: 'relative',
  width: '100%',
  marginTop: 18,
});
