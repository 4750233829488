import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CheckboxInput } from '../FormElements';
import {
  V2SingleSelectComponent,
  V2MultiSelectComponent,
  DynamicLoaderType,
  V2AggregateLoader,
} from '@terragotech/form-renderer';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { JSONSchema6 } from 'json-schema';
import { V2OptionsInput, isSimpleOption } from '../FormElements/V2OptionsInput';
import { LocalSchemaDefinition } from '../../utils/useSchemaLookup';
import { useFormSchemas } from './hooks/useFormSchemas';
import RepeatableComponent, { defaultRepeatableProps } from '../RepeatableComponent';
import { DUPLICATE_NAME_ERROR_MESSAGE } from '../../pages/aggregates/utils/formUtils';
import _ from 'lodash';
import { expandPath } from './TextInputEditForm';
import { useStyles } from './useStyles';
import { FormEditProps } from 'utils/types';
import { TextInput } from './common';
import MapperItem from './MapperItem';
import FormValidation from './FormValidation';
import { Mapper } from './MapperDefinition';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION } from 'utils/Utils';

export type SingleSelectWithName =
  | (V2SingleSelectComponent & {
      name: string;
      droppableId: string;
      index?: number;
    })
  | (V2MultiSelectComponent & {
      name: string;
      droppableId: string;
      index?: number;
    });

interface OptionsInputEditFormProps extends FormEditProps {
  component: SingleSelectWithName;
}

export const OptionsInputEditForm: React.FC<OptionsInputEditFormProps> = ({
  existingNameError,
  handleNameChange,
  component,
  getEditedDef,
  setFormDefinition,
}) => {
  const classes = useStyles();
  const [oldName, setOldName] = useState(component.name);
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState(component.label);
  const [required, setRequired] = useState(component.required);
  const [placeholder, setPlaceholder] = useState(component.placeholder);
  const [description, setDescription] = useState(component.description);
  const [info, setInfo] = useState(component.info);
  const [readOnly, setReadOnly] = useState(component.readOnly);
  const [options, setOptions] = useState(component.options);
  const [errorMap, setErrorMap] = useState<NodeMapDefinition[] | undefined>(
    component.errorMap || undefined
  );
  const [warningMap, setWarningMap] = useState<NodeMapDefinition[] | undefined>(
    component.warningMap || undefined
  );
  const [conditionalMap, setConditionalMap] = useState(component.conditionalMap || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const [repeats, setRepeats] = useState(
    component.repeats ? { ...defaultRepeatableProps, ...component.repeats } : defaultRepeatableProps
  );
  const [droppableId, setDroppableId] = useState(component.droppableId);
  const [type, setType] = useState(component.type);
  const formSchemas = useFormSchemas();
  const { openConfirmation } = useConfirmDialog();

  const setInitialData = useCallback(() => {
    setName(component.name || '');
    setOldName(component.name || '');
    setLabel(component.label || '');
    setRequired(component.required);
    setPlaceholder(component.placeholder);
    setDescription(component.description);
    setType(component.type);
    setDroppableId(component.droppableId);
    setInfo(component.info);
    setReadOnly(component.readOnly);
    setRepeats(
      component.repeats
        ? { ...defaultRepeatableProps, ...component.repeats }
        : defaultRepeatableProps
    );
    setErrorMap(component.errorMap || undefined);
    setWarningMap(component.warningMap || undefined);
    setConditionalMap(component.conditionalMap || undefined);
    setOptions(component.options);
  }, [component]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  const getOptions = useCallback(() => {
    const optionsCopy = _.cloneDeep(options);
    if (isSimpleOption(optionsCopy)) {
      const { type, items } = optionsCopy;
      return { type, items: items || [] };
    } else if (optionsCopy.type === DynamicLoaderType) {
      const { type, optionsMap } = optionsCopy;
      return { type, optionsMap };
    } else {
      const { type, defaultFilter, mandatoryFilter } = optionsCopy;
      return {
        type,
        aggregateType: (optionsCopy as V2AggregateLoader).aggregateType,
        ...(defaultFilter && { defaultFilter }),
        ...(mandatoryFilter && { mandatoryFilter }),
      };
    }
  }, [options]);

  const getFormValues = useCallback(() => {
    return {
      type: type,
      name,
      label,
      ...(placeholder && { placeholder }),
      ...(required !== undefined && { required }),
      ...(description && { description }),
      ...(info && { info }),
      ...(readOnly !== undefined && { readOnly }),
      repeats,
      options: getOptions(),
      ...(conditionalMap && { conditionalMap }),
      ...(errorMap && { errorMap }),
      ...(warningMap && { warningMap }),
      droppableId: droppableId,
    };
  }, [
    droppableId,
    type,
    name,
    label,
    placeholder,
    required,
    description,
    info,
    readOnly,
    repeats,
    conditionalMap,
    errorMap,
    warningMap,
    getOptions,
  ]);

  useEffect(() => {
    if (getEditedDef && setFormDefinition && name.length > 0 && !existingNameError) {
      const editedDef = getEditedDef({
        editedData: getFormValues(),
        oldName,
        newName: name,
        droppableId: component.droppableId,
      });
      if (editedDef) {
        setFormDefinition(editedDef);
        if (oldName !== name) {
          setOldName(name);
        }
      }
    }
    // If add those all dependency, it'll call recursively
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFormValues, name]);

  const parts = (component.droppableId || '').split('.');
  const t =
    !component.droppableId || component.droppableId === 'form'
      ? []
      : expandPath(parts, formSchemas.FORM.schema.properties as JSONSchema6);

  let formValue = { value: { type: 'string' } };
  for (let i = 0; i < t.length; i++) {
    formValue = { ...formValue, ...t[i] };
  }
  const errorWarningSchemas: LocalSchemaDefinition = {
    FORMVALUE: {
      schema: { type: 'object', properties: formValue } as JSONSchema6,
      schemaLabel: 'Current Field Value',
    },
    ...formSchemas,
  };

  const handleClearConditionalMapper = async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      setConditionalMap(undefined);
    }
  };

  const doesConditionalHaveValue = useCallback(() => {
    return !_.isEmpty(conditionalMap);
  }, [conditionalMap]);

  const mapperList: Mapper[] = [
    {
      title: 'Error Mapping',
      data: errorMap || [],
      localSchemas: errorWarningSchemas,
      setData: (data) => setErrorMap(data),
      mapScenario: 'FIELD_LEVEL_ERROR',
      containerStyle: classes.firstMapper,
    },
    {
      title: 'Warning Mapping',
      data: warningMap || [],
      localSchemas: errorWarningSchemas,
      setData: (data) => setWarningMap(data),
      mapScenario: 'FIELD_LEVEL_WARNING',
      containerStyle: classes.secondMapper,
    },
  ];

  return (
    <>
      <Typography className={classes.header}>{name}</Typography>
      <TextInput
        autoFocus
        id="Name"
        label="Name"
        error={existingNameError}
        helperText={existingNameError ? DUPLICATE_NAME_ERROR_MESSAGE : ''}
        value={name}
        onChange={(value) => handleNameChange && handleNameChange(value || '', setName)}
        placeholder="Enter name"
      />
      <TextInput
        id="Label"
        label="Label"
        value={label}
        onChange={(value) => setLabel(value || '')}
        placeholder="Enter label"
      />
      <TextInput
        id="Placeholder"
        label="Placeholder"
        value={placeholder}
        onChange={setPlaceholder}
        placeholder="Enter placeholder"
      />
      <TextInput
        id="Description"
        label="Description"
        value={description}
        onChange={setDescription}
        placeholder="Enter description"
      />
      <TextInput
        id="Info"
        label="Info"
        value={info}
        onChange={setInfo}
        className={classes.bottom30}
        placeholder="Enter info"
      />
      <Box className={`${classes.itemContainer} ${classes.bottom20}`}>
        <Typography className={classes.label}>Additional Options</Typography>
        <Box className={classes.checkBoxContainer}>
          <CheckboxInput title="Required" checked={required} onChange={setRequired} />
          <CheckboxInput
            title="Read-only"
            checked={readOnly}
            onChange={(value) => setReadOnly(value)}
          />
        </Box>
      </Box>

      <MapperItem
        {...{
          onToggleMapper: setConditionalOpen,
          isActive: doesConditionalHaveValue(),
          clearMapper: handleClearConditionalMapper,
          openDataMap: conditionalOpen,
          dataMap: conditionalMap,
          setDataMap: setConditionalMap,
          localSchemaDefinition: errorWarningSchemas,
          title: 'Conditional Map',
          mapScenario: 'FORM_FIELD_CONDITIONAL',
        }}
      />
      <V2OptionsInput
        options={options}
        setOptions={setOptions}
        localSchemas={errorWarningSchemas}
      />
      <Box className={`${classes.itemContainer} ${classes.bottom20}`}>
        <Typography className={classes.label}>Repeating Instances</Typography>
        <RepeatableComponent repeats={repeats} setRepeats={setRepeats} />
      </Box>
      <FormValidation mapperList={mapperList} />
    </>
  );
};
