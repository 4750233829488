import React from 'react';
import { makeStyles, Box, Typography, Checkbox } from '@material-ui/core';
import _ from 'lodash';
import { colors } from 'utils/colors';
import TGSwitch from './FormElements/Switch';
import { TitleToolTip } from 'pages/aggregates/components/TitleToolTip';
import { TextInputTG } from 'views/components/formElements';
import FormLegend from './FormLegend';

interface MultipleProps {
  enabled: boolean;
  maximum: number;
  unlimited: boolean;
}
interface ComponentProps {
  multiplePoints?: MultipleProps;
  setMultiplePoints: React.Dispatch<React.SetStateAction<MultipleProps | undefined>>;
  disabledSwitch?: boolean;
}
export const defaultMultiplePointsProps: MultipleProps = {
  enabled: false,
  maximum: 1,
  unlimited: false,
};

/*
//todo: in order to give backward compatibility we need to change the definition like below inside the formrenderer library
export interface V2MultiplePointsTemplateComponent extends V2FormComponent {
  multiplePoints?: {
    enabled: boolean;
    maximum: number;
    unlimited: boolean;
  };
}*/
const MultiplePoints = (props: ComponentProps) => {
  const { multiplePoints, setMultiplePoints, disabledSwitch } = props;
  const classes = useStyles();

  return (
    <FormLegend
      title="Multiple points per instance"
      tooltip={'Configure if this will have multiple points corresponding to one instance'}
      disabled={disabledSwitch}
      rightContent={
        <TGSwitch
          onChange={(checked) => {
            if (checked === true) {
              if (multiplePoints) {
                setMultiplePoints({ ...multiplePoints, enabled: checked });
              } else {
                setMultiplePoints({ ...defaultMultiplePointsProps, enabled: checked });
              }
            } else {
              setMultiplePoints(undefined);
            }
          }}
          checked={multiplePoints?.enabled ?? false}
          disabled={disabledSwitch}
        />
      }
      body={
        multiplePoints?.enabled ? (
          <Box className={classes.body}>
            <Box className={classes.itemContainer}>
              <Box className={classes.titleRow}>
                <TitleToolTip
                  title="Maximum"
                  tooltipText={
                    'The maximum number of instances per aggregate (must be greater than 0), unlimited switch means no maximum'
                  }
                  titleStyle={classes.title}
                />

                <Box className={classes.inlineBox}>
                  <Checkbox
                    checked={multiplePoints.unlimited}
                    className={classes.checkbox}
                    onChange={(v, checked) =>
                      setMultiplePoints({ ...multiplePoints, unlimited: checked })
                    }
                    color="primary"
                  />

                  <Typography className={classes.limitText}>unlimited</Typography>
                </Box>
              </Box>
              <TextInputTG
                id="Maximum"
                value={!multiplePoints.unlimited ? multiplePoints.maximum : 'unlimited'}
                fullWidth
                type={!multiplePoints.unlimited ? 'number' : 'text'}
                placeholder="Enter maximum"
                className={classes.zeroMargin}
                disabled={multiplePoints.unlimited}
                onChange={
                  !multiplePoints.unlimited
                    ? (value) =>
                        setMultiplePoints({ ...multiplePoints, maximum: _.toNumber(value) })
                    : undefined
                }
              />
            </Box>
          </Box>
        ) : (
          <Box className={classes.empty}>
            <Typography className={classes.emptyMessage}>
              Multiple points have not set up
            </Typography>
          </Box>
        )
      }
    />
  );
};

const useStyles = makeStyles({
  title: {
    fontSize: 15,
    fontWeight: 400,
    color: colors.black,
    lineHeight: '100%',
  },
  body: {
    padding: '14px 18px',
  },
  empty: {
    padding: '17px 24px',
  },
  emptyMessage: {
    color: colors.black54,
    fontSize: 15,
    fontWeight: 400,
  },
  itemContainer: {
    width: '100%',
    position: 'relative',
    marginBottom: 19,
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inlineBox: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  zeroMargin: { margin: 0 },
  checkbox: {
    marginRight: 0,
  },
  limitText: {
    fontSize: 15,
    color: colors.black,
    paddingRight: 5,
  },
});

export default MultiplePoints;
