import CommandProcessorLib, {
    CommandInstanceType,
    CommandResult,
} from '@terragotech/gen5-command-processing-lib';
import { Gen5Config } from '@terragotech/gen5-config-lib';

export const processCommand = async (
  gen5config: Gen5Config,
  offlineDataLookup: any,
  offlineProximityLookup: any,
  command: CommandInstanceType
): Promise<CommandResult> => {
  const commandProcessor: CommandProcessorLib = new CommandProcessorLib({
    OFFLINE_ATTRIB_LOOKUP: offlineDataLookup,
    ONLINE_ATTRIB_LOOKUP: offlineDataLookup,
    OFFLINE_PROXIMITY_LOOKUP: offlineProximityLookup,
    ONLINE_PROXIMITY_LOOKUP: offlineProximityLookup,
  });

  commandProcessor.initfromGen5Config(gen5config);

  return await commandProcessor.processCommand(command);
};