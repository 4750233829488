import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { MapScenarioType } from '@terragotech/gen5-datamapping-lib';
import { EventMapDiagram } from '../../../../../map-editor/src';
import { UseEventMapDiagramOutput } from '../../../../../map-editor/src/Hooks/useDataMapDiagram';
import { FullScreen } from '../../../../../components/FullScreen';

const HEADER_HEIGHT = 92;
interface DataMapperProps {
  eventMapDiagramOutput: UseEventMapDiagramOutput;
  mapScenario: keyof MapScenarioType;
}

function DataMapper(dataMapperProps: DataMapperProps) {
  const { eventMapDiagramOutput, mapScenario } = dataMapperProps;
  return (
    <DataMapperWrapped
      eventMapDiagramOutput={eventMapDiagramOutput}
      mapScenario={mapScenario}
    />
  );
}

function DataMapperWrapped({ eventMapDiagramOutput, mapScenario }: DataMapperProps) {
  const { engine, model, hasSetPosition, setInitialDataMap } = eventMapDiagramOutput;

  return (
    <EventMapperContainer>
      <FullScreen zoomButton={styles.zoomButton}>
        <EventMapDiagram
          engine={engine}
          model={model}
          mapScenario={mapScenario}
          hasSetPosition={hasSetPosition}
          setInitialDataMap={setInitialDataMap}
        />
      </FullScreen>
    </EventMapperContainer>
  );
}
const EventMapperContainer = styled(Box)`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  width: 100%;
`;

const styles = {
  zoomButton: {
    top: 3,
    left: 7,
  }
}
export default DataMapper;
