import { Grid, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';
import { CommandFormEditor } from 'pages/aggregates/components/CommandFormEditor/CommandFormEditor';
import {
  V2FormComponentDef,
  V2FormTemplate,
  V2GroupComponentDef,
} from '@terragotech/form-renderer';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useConfig } from 'context/ConfigContext';
import { useParams } from 'react-router-dom';
import { getAggregateIndex } from 'utils/navigationUtils';
import { useMapperRefChanger } from 'utils/useMapperRefChanger';
import { cloneDeep } from 'lodash';
import { V2FormComponentDefWithName } from 'pages/aggregates/components/CommandFormEditor/CommandFormList';
import { TextHeaderEditForm } from 'components/FormDialog/TextHeaderEditForm';
import { AggregateContextProvider } from 'components/FormDialog/contexts/AggregateContext';
import { FormContextProvider } from 'components/FormDialog/contexts/FormContext';
import { successMsg } from 'components/SnackbarUtilsConfigurator';
import { GroupEditForm } from 'components/FormDialog/GroupEditForm';
import { TextInputEditForm } from 'components/FormDialog/TextInputEditForm';
import { MapAggregateSelector } from 'components/FormDialog/MapAggregateSelectorForm';
import { OptionsInputEditForm } from 'components/FormDialog/OptionsInputEditForm';
import { MediaEditForm } from 'components/FormDialog/MediaEditForm';
import { EmptyCommand } from 'views/pages/records/EmptyCommand';
import { checkDuplicateFormName } from 'pages/aggregates/utils/formUtils';
import _ from 'lodash';
import { EditedDefData } from '../../../../../../utils/types';
import { CommandVersionDefinition } from '@terragotech/gen5-config-lib';

export interface FormEditorEditedComponentProps {
  name: string;
  index: number;
  droppableId: string;
}

interface Props {
  formDefinition: V2FormTemplate;
  isImportCommand: boolean;
  isImport: boolean;
  setFormDefinition: Dispatch<SetStateAction<V2FormTemplate>>;
  formEditorEditedData: null | V2FormComponentDefWithName;
  setFormEditorEditedData: (val: null | V2FormComponentDefWithName) => void;
  formEditorEditedComponent: FormEditorEditedComponentProps | null;
  setFormEditorEditedComponent: (val: FormEditorEditedComponentProps | null) => void;
  setFormComponentNameError: (val: boolean) => void;
  getEditedDef?: (val: EditedDefData) => V2FormTemplate | null;
  focusedItem: string;
  setFocusedItem: (val: string) => void;
  version: CommandVersionDefinition | undefined;
}

const FormEditor = ({
  formDefinition,
  isImportCommand,
  isImport,
  setFormDefinition,
  formEditorEditedData,
  setFormEditorEditedData,
  formEditorEditedComponent,
  setFormEditorEditedComponent,
  setFormComponentNameError,
  getEditedDef,
  focusedItem,
  setFocusedItem,
  version,
}: Props) => {
  const classes = useStyles();
  const { config } = useConfig();
  const { aggregate: aggrName } = useParams() as {
    aggregate: string;
  };
  const aggrIndex = getAggregateIndex(config, aggrName);
  const currentAggregate = config?.aggregates?.[aggrIndex];
  const [currentEditItem, setCurrentEditItem] = useState<null | V2FormComponentDefWithName>(null);
  useEffect(() => {
    setCurrentEditItem(null);
    setFocusedItem('');
  }, [version, isImport, setFocusedItem]);
  const [existingNameError, setExistingNameError] = useState(false);
  const mapperRefChanger = useMapperRefChanger();

  useEffect(() => {
    setFormComponentNameError(existingNameError);
  }, [existingNameError, setFormComponentNameError]);

  const handleSelectedItem = (
    event: React.MouseEvent | null,
    formComponentName: string,
    group?: string,
    item?: V2FormComponentDefWithName
  ) => {
    setFocusedItem(`${group || ''}${formComponentName}`);
    if (!_.isUndefined(item)) {
      setCurrentEditItem(item);
    }
  };
  const editItem = async (
    component: V2FormComponentDef,
    name: string,
    droppableId: string,
    index: number
  ) => {
    const comp = cloneDeep(component);
    const item: V2FormComponentDefWithName = { ...comp, name, droppableId, index };
    setCurrentEditItem(item);
  };
  const deleteItem = async (id: string, droppableId: string, index: number) => {
    let form = cloneDeep(formDefinition);
    const removeItemFromForm = () => {
      form.order.splice(index, 1);
      delete form.components[id];
    };
    const removeItemFromGroup = () => {
      dropOrder.splice(index, 1);
      delete dropComponents[id];
    };
    successMsg(`Form element "${id}" has been successfully deleted`);
    setCurrentEditItem(null);
    if (droppableId === 'form') {
      removeItemFromForm();
      form = mapperRefChanger.removeFormReferences(form, id);
      setFormEditorEditedData(null);
      setFormEditorEditedComponent(null);
      setFormDefinition(form);
      return;
    }
    const {
      template: { order: dropOrder, components: dropComponents },
    } = form.components[droppableId] as V2GroupComponentDef;
    removeItemFromGroup();
    form = mapperRefChanger.removeFormReferences(form, id, droppableId);
    setFormEditorEditedData(null);
    setFormEditorEditedComponent(null);
    setFormDefinition(form);
  };

  return (
    <Grid container className={classes.gridCtn}>
      <Grid item sm={4} className={classes.formFieldContainer}>
        <CommandFormEditor
          formEditorEditedData={formEditorEditedData}
          formEditorEditedComponent={formEditorEditedComponent}
          setFormEditorEditedData={setFormEditorEditedData}
          formDefinition={formDefinition}
          isImportCommand={isImportCommand}
          isImport={isImport}
          setFormDefinition={(val) => setFormDefinition(val)}
          handleSelectedItem={handleSelectedItem}
          editItem={editItem}
          deleteItem={deleteItem}
          hasError={existingNameError}
          focusedItem={focusedItem}
          setFormEditorEditedComponent={setFormEditorEditedComponent}
        />
      </Grid>
      <Grid item sm={8} className={classes.formDetailContainer}>
        {currentEditItem !== null ? (
          <AggregateContextProvider aggregateConfig={currentAggregate}>
            <FormContextProvider formDefinition={formDefinition}>
              <div className={classes.column}>
                {getFormComponent(
                  formDefinition,
                  existingNameError,
                  setExistingNameError,
                  currentEditItem,
                  setFormEditorEditedData,
                  isImportCommand,
                  getEditedDef,
                  setFormDefinition
                )}
              </div>
            </FormContextProvider>
          </AggregateContextProvider>
        ) : (
          <>
            <EmptyCommand
              title="Forms Not Added"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  gridCtn: {
    height: '100%',
  },
  formFieldContainer: {
    height: '100%',
    backgroundColor: colors.lotion,
    borderRight: `1px solid ${colors.black10}`,
  },
  formDetailContainer: {
    backgroundColor: colors.white,
    padding: 22,
    paddingLeft: 30,
    height: '100%',
    overflowY: 'scroll',
  },
  column: { display: 'flex', flexDirection: 'column' },
});

const getFormComponent = (
  formDefinition: V2FormTemplate,
  existingNameError: boolean,
  setExistingNameError: (hasError: boolean) => void,
  item: V2FormComponentDefWithName,
  setComponentEditedData: (val: V2FormComponentDefWithName) => void,
  isImportCommand?: boolean,
  getEditedDef?: (val: EditedDefData) => V2FormTemplate | null,
  setFormDefinition?: (val: V2FormTemplate) => void
) => {
  const checkDuplicateName = (name: string) => {
    return checkDuplicateFormName(name, item, formDefinition.components);
  };

  const handleNameChange = (name: string, setName: (name: string) => void) => {
    const isName = checkDuplicateName(name);
    setExistingNameError(isName);
    setName(name);
  };

  switch (item.type) {
    case 'textheader':
      return (
        <TextHeaderEditForm
          existingNameError={existingNameError}
          handleNameChange={handleNameChange}
          component={item}
          getEditedDef={getEditedDef}
          setFormDefinition={setFormDefinition}
        />
      );
    case 'group':
      return (
        <GroupEditForm
          existingNameError={existingNameError}
          handleNameChange={handleNameChange}
          component={item}
          isImportCommand={isImportCommand}
          getEditedDef={getEditedDef}
          setFormDefinition={setFormDefinition}
        />
      );
    case 'textInput':
    case 'textArea':
    case 'numberInput':
      return (
        <TextInputEditForm
          getEditedDef={getEditedDef}
          setFormDefinition={setFormDefinition}
          existingNameError={existingNameError}
          handleNameChange={handleNameChange}
          component={item}
          isImportCommand={isImportCommand}
          autosuggest
        />
      );
    case 'computed':
      return (
        <TextInputEditForm
          existingNameError={existingNameError}
          handleNameChange={handleNameChange}
          component={item}
          isImportCommand={isImportCommand}
          computed
          getEditedDef={getEditedDef}
          setFormDefinition={setFormDefinition}
        />
      );
    case 'date':
    case 'time':
    case 'barcode':
    case 'location':
    case 'polyline':
      return (
        <TextInputEditForm
          existingNameError={existingNameError}
          handleNameChange={handleNameChange}
          component={item}
          isImportCommand={isImportCommand}
          getEditedDef={getEditedDef}
          setFormDefinition={setFormDefinition}
        />
      );
    case 'mapAggregateSelector':
      return (
        <MapAggregateSelector
          existingNameError={existingNameError}
          handleNameChange={handleNameChange}
          component={item}
          getEditedDef={getEditedDef}
          setFormDefinition={setFormDefinition}
        />
      );
    case 'radio':
    case 'select':
    case 'checkbox':
      return (
        <OptionsInputEditForm
          existingNameError={existingNameError}
          handleNameChange={handleNameChange}
          component={item}
          getEditedDef={getEditedDef}
          setFormDefinition={setFormDefinition}
        />
      );
    case 'audioupload':
    case 'imageupload':
    case 'fileupload':
    case 'videoupload':
      return (
        <MediaEditForm
          existingNameError={existingNameError}
          handleNameChange={handleNameChange}
          component={item}
          getEditedDef={getEditedDef}
          setFormDefinition={setFormDefinition}
        />
      );
    default:
      return null;
  }
};

export default FormEditor;
