import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { V2GroupComponentDef, V2TextHeaderComponent } from '@terragotech/form-renderer';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { FormControlProps } from '../FormTypes';
import { controlMap } from '.';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Box, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';
import { useFormDialog } from 'components/FormDialog/FormDialogService';
import { FormPalette } from 'views/pages/records/components/commands/tabs/FormPalette';
import { cloneDeep } from 'lodash';
import { createFormItem, generateFormItemName } from '../defaultFormItems';
import { errorMsg } from '../../../../../components/SnackbarUtilsConfigurator';
import { CONFIRMATION, FORM_SAME_NAME_ERROR_MESSAGE } from '../../../../../utils/Utils';
import { FormBadges } from './FormBadge';
import { useConfirmDialog } from 'context/ConfirmContext';

const GroupControl: FunctionComponent<FormControlProps<V2GroupComponentDef> & { name: string }> = ({
  controlDefinition,
  name,
  isDropDisabled,
  setSelectedItems,
  focusedItem,
  editItem,
  deleteItem,
  lastPastedFormGroup,
  formDefinition,
  setFormDefinition,
  isImport,
  hasError,
}) => {
  const classes = useStyles();
  const { template } = controlDefinition;
  const openDialog = useFormDialog();
  const { openConfirmation } = useConfirmDialog();

  const addItem = (event: React.MouseEvent, type: string) => {
    if (formDefinition && setFormDefinition) {
      let groupName = name;
      let form = cloneDeep(formDefinition);
      const {
        template: { order: templateOrder, components: dropComponents },
      } = form.components[groupName] as V2GroupComponentDef;
      const compName = generateFormItemName(type, templateOrder);
      const item = createFormItem(type, compName);
      dropComponents[compName] = item;
      templateOrder.push(compName);
      setFormDefinition(form);
      setSelectedItems(event, compName, groupName, {
        ...item,
        name: compName,
        droppableId: groupName,
        index: templateOrder.length - 1,
      });
    }
  };

  const openPalette = async (event: React.MouseEvent) => {
    const actionButtons = (await openDialog<typeof FormPalette>((props) => (
      <FormPalette isImportCommand={false} isImportGroup={!!isImport} {...props} />
    ))) as {
      type: string;
    };
    const { type } = actionButtons;
    addItem(event, type);
  };
  const handleItemClick = (
    event: React.MouseEvent,
    componentName: string,
    name: string,
    index: number,
    component?: V2TextHeaderComponent
  ) => {
    event.stopPropagation();
    if (hasError) {
      errorMsg(FORM_SAME_NAME_ERROR_MESSAGE);
      return;
    }
    setSelectedItems(event, componentName, name);
    editItem(component as V2TextHeaderComponent, componentName, name, index);
  };
  const handleEvent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    openPalette(event);
  };

  const handleRemoveItem = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    componentName: string,
    index: number
  ) => {
    e.stopPropagation();
    const props = CONFIRMATION.commandForm({ name: componentName });
    const status = await openConfirmation(props);
    if (status === 'confirm') {
      deleteItem(componentName, name, index);
    }
  };

  return (
    <Panel>
      <Droppable droppableId={name} isDropDisabled={isDropDisabled}>
        {(provided) => (
          <GroupInner {...provided.droppableProps} ref={provided.innerRef}>
            <PanelHeader>
              <Typography className={classes.panelTitle}>{controlDefinition.label}</Typography>
              <IconButton
                onClick={(e) => {
                  handleEvent(e);
                }}
                className={classes.plusButton}
              >
                <FontAwesomeIcon icon={faPlus} className={classes.plusIcon} />
              </IconButton>
            </PanelHeader>
            <FormBadges controlDefinition={controlDefinition} isGroup />

            <PanelBody>
              {template.order.map((componentName, index) => {
                // "as V2TextHeaderComponent" hardcoded because a strange TS issue occures"
                const component = template.components[componentName] as V2TextHeaderComponent;
                const Control = component && controlMap[component.type];
                if (Control) {
                  return (
                    <Draggable
                      key={name + componentName}
                      draggableId={name + componentName}
                      index={index}
                      isDragDisabled={isDropDisabled}
                    >
                      {(provided) => (
                        <Box
                          //@ts-ignore
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`${classes.panelItem} ${
                            focusedItem === name + componentName ? classes.selected : ''
                          }`}
                          onClick={(event) => {
                            handleItemClick(event, componentName, name, index, component);
                          }}
                        >
                          <Control
                            controlDefinition={component}
                            name={componentName}
                            isImport={isImport}
                            editItem={editItem}
                            setSelectedItems={setSelectedItems}
                            pasted={lastPastedFormGroup?.template.order.includes(componentName)}
                          />
                          {focusedItem === name + componentName && (
                            <div className={classes.trashContainer}>
                              <IconButton
                                className={classes.trashButton}
                                component="span"
                                onClick={(e) => handleRemoveItem(e, componentName, index)}
                              >
                                <FontAwesomeIcon icon={faTrashCan} className={classes.trashIcon} />
                              </IconButton>
                            </div>
                          )}
                        </Box>
                      )}
                    </Draggable>
                  );
                } else {
                  console.log(`Couldn't find component: ${component.type}`);
                }
                return null;
              })}
              {provided.placeholder}
            </PanelBody>
          </GroupInner>
        )}
      </Droppable>
    </Panel>
  );
};

const Panel = styled.div`
  overflow: hidden;
`;
const PanelHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 18px;
  padding-bottom: 12px;
`;
const PanelBody = styled.div`
  padding: 0 11px;
`;
const GroupInner = styled.div``;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    plusButton: {
      marginLeft: 1,
      padding: 6,
    },
    plusIcon: {
      color: theme.palette.primary.main,
      fontSize: 15,
    },
    trashIcon: {
      fontSize: 18,
    },
    trashButton: { color: colors.black54, padding: 3, marginRight: 3 },
    trashContainer: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    panelTitle: {
      fontSize: 15,
      fontWeight: 500,
      color: colors.black,
    },

    panelItem: {
      position: 'relative',
      background: colors.white,
      color: colors.title,
      boxShadow: `0px 1px 4px 0px ${colors.black15}`,
      border: '1px solid transparent',
      borderRadius: 5,
      overflow: 'hidden',
      marginBottom: 10,
      width: '100%',
    },
    selected: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  })
);

export default GroupControl;
