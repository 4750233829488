import React from 'react';
import { DialogActions, Button, DialogTitle, DialogContent, makeStyles } from '@material-ui/core';
interface Button {
  label: string;
  value: string;
}

interface Props {
  onSubmit: (result: string) => void;
  onClose: () => void;
  options: Button[];
  description?: string;
  title?: string;
}

export const MultiButtonDialog: React.FC<Props> = ({
  onSubmit,
  onClose,
  options,
  description,
  title,
}) => {
  const classes = useStyles();
  return (
    <>
      <DialogTitle>{title || 'Which option do you choose?'}</DialogTitle>

      {description && <DialogContent className={classes.content}>{description}</DialogContent>}

      <DialogActions className={classes.actions}>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        {options.map((option) => (
          <Button
            color="primary"
            focusRipple={false}
            onClick={() => onSubmit(option.value)}
            autoFocus
          >
            {option.label}
          </Button>
        ))}
      </DialogActions>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actions: { marginRight: 10, marginTop: 5 },
  content: { maxWidth: 650 },
}));
