import { faSave } from '@fortawesome/pro-light-svg-icons';
import { Box, Divider } from '@material-ui/core';
import PageHeader from 'views/components/PageHeader';
import useCommonStyles from 'views/useCommonStyles';
import { CommonSettings, SelectProps } from './commonTypes';
import { useContext, useState } from 'react';
import RowItem from 'views/components/RowItem';
import { ConfigContext } from 'context/ConfigContext';
import { infoMsg, successMsg } from 'components/SnackbarUtilsConfigurator';
import { MultiButtonDialog } from 'components/FormDialog/MultiButtonDialog';
import { useFormDialog } from 'components/FormDialog/FormDialogService';
import useRouteBlocker from '../../../../../common/useBlocker';
import { useBlocker } from 'react-router-dom';

interface MeasurementType {
  label: string;
  value: 'feet' | 'yards' | 'miles' | 'meters' | 'kilometers';
}
interface PrecisionType {
  label: string;
  value: 'ones' | 'tenths' | 'hundredths' | 'thousandths';
}

const MapOptions = () => {
  const commonClasses = useCommonStyles();
  const {
    setGeographicMobile,
    getGeographicMobile,
    getGeographicWeb,
    setGeographicWeb,
    setMapOptions,
    getNearMaps,
    getMapServices,
  } = useContext(ConfigContext);
  const [nearMapSwitch, setNearMapSwitch] = useState<boolean>(getNearMaps());
  const [mapServiceSwitch, setMapServiceSwitch] = useState<boolean>(getMapServices());
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const formDialog = useFormDialog();

  const measurementLabelsAndValues: MeasurementType[] = [
    { label: 'Feet (ft)', value: 'feet' },
    { label: 'Yards (yd)', value: 'yards' },
    { label: 'Miles (mi)', value: 'miles' },
    { label: 'Meters (m)', value: 'meters' },
    { label: 'Kilometers (km)', value: 'kilometers' },
  ];
  const precisionLabelsAndValues: PrecisionType[] = [
    { label: 'Ones (123)', value: 'ones' },
    { label: 'Tenths (123.1)', value: 'tenths' },
    { label: 'Hundredths (123.12)', value: 'hundredths' },
    { label: 'Thousandths (123.123)', value: 'thousandths' },
  ];
  const [mandatoryClusteringSwitch, setMandatoryClusteringSwitch] = useState<boolean>(
    getGeographicMobile().mapSettings?.isMandatoryClusteringEnabled ?? true
  );
  const geographicWeb = getGeographicWeb().geographic;

  const [panMapSwitch, setPanMapSwitch] = useState<boolean>(
    (getGeographicMobile().enabledFeatures?.refocusToCurrentLocationAfterDirections as boolean) ??
      false
  );
  const initialMeasurementLabel =
    measurementLabelsAndValues.find((x) => x.value === geographicWeb?.polylineUnitOfMeasurement)
      ?.label ?? measurementLabelsAndValues[0].label;
  const initialPrecisionLabel =
    precisionLabelsAndValues.find((x) => x.value === geographicWeb?.polylineRoundingPrecision)
      ?.label ?? precisionLabelsAndValues[0].label;

  const [measurementLabel, setMeasurementLabel] = useState<string>(initialMeasurementLabel);
  const [precisionLabel, setPrecisionLabel] = useState<string>(initialPrecisionLabel);

  const onSwitchChange = () => {
    setIsDirty(true);
    setMandatoryClusteringSwitch((x) => !x);
  };

  const onPanMapChange = () => {
    setIsDirty(true);
    setPanMapSwitch((x) => !x);
  };

  const onNearMapSwitchChange = () => {
    setIsDirty(true);
    setNearMapSwitch((x) => !x);
  };

  const onMapServiceSwitchChange = () => {
    setIsDirty(true);
    setMapServiceSwitch((x) => !x);
  };

  const onSave = async (): Promise<void> => {
    setGeographicMobile(mandatoryClusteringSwitch, panMapSwitch);
    setGeographicWeb(
      measurementLabelsAndValues.find((x) => x.label === measurementLabel)?.value,
      precisionLabelsAndValues.find((x) => x.label === precisionLabel)?.value
    );
    successMsg('Geographic has been saved successfully');
    if (!mapServiceSwitch && mapServiceSwitch !== getMapServices()) {
      const confirm = await formDialog<typeof MultiButtonDialog>((props) => (
        <MultiButtonDialog
          title="Disable Map Options"
          description="Disabling Map Services will remove the related aggregate and data. Any aggregate and UI customizations made for Map Services will be lost. This cannot be undone. Are you sure you want to disable this option?"
          options={[{ value: 'true', label: 'Save' }]}
          {...props}
        />
      ));

      if (!confirm) {
        infoMsg('Map Options Integration changes have not been saved');
        return;
      }
    }
    setIsDirty(false);
    setMapOptions({ nearMaps: nearMapSwitch, mapServices: mapServiceSwitch });
    successMsg('Map Options Integration has been saved successfully');
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && isDirty
  );
  useRouteBlocker({ blocker, onSave: onSave });

  const mapOptions: CommonSettings[] = [
    {
      title: 'Mobile Map Features',
      description: '',
      components: [
        {
          type: 'iosSwitch' as const,
          key: 'forceClustering',
          title: 'Force clustering of overlapping map symbols',
          description:
            'Enabling this will show clusters on overlapping map symbols, even if the user has clustering disabled',
          checked: mandatoryClusteringSwitch,
          action: onSwitchChange,
        },
        {
          type: 'iosSwitch' as const,
          key: 'panMap',
          title: 'Pan map after directions',
          description:
            'When the user leave the app and returns, the map bounds will be updated to the users current location',
          checked: panMapSwitch,
          action: onPanMapChange,
        },
      ],
    },
    {
      title: 'Web Map Features',
      description: '',
      components: [
        {
          type: 'iosSwitch' as const,
          key: 'nearMap',
          title: 'Enable NearMap Basemap',
          description: 'The NearMap API key must be added to the server as an ENV',
          checked: nearMapSwitch,
          action: onNearMapSwitchChange,
        },
        {
          type: 'iosSwitch' as const,
          key: 'map',
          title: 'Enable Map Services',
          description:
            'Turning on Map Services will create a new aggregate and related data to support external map layers.',
          checked: mapServiceSwitch,
          action: onMapServiceSwitchChange,
        },
        {
          type: 'header' as const,
          key: 'header',
          label: 'Polyline Measurement settings',
        },
        {
          type: 'select' as const,
          key: 'unitOfMeasurement',
          label: 'Unit of Measurement',
          value: measurementLabel,
          options: measurementLabelsAndValues.map((x) => x.label),
          action: (label) => {
            setIsDirty(true);
            setMeasurementLabel(label as string);
          },
        } as SelectProps,
        {
          type: 'select' as const,
          key: 'roundingPrecision',
          label: 'Rounding Precision',
          value: precisionLabel,
          options: precisionLabelsAndValues.map((x) => x.label),
          action: (label) => {
            setIsDirty(true);
            setPrecisionLabel(label as string);
          },
        } as SelectProps,
      ],
    },
  ];
  return (
    <Box className={commonClasses.container}>
      <Box className={commonClasses.innerContainer}>
        <PageHeader
          title={'Map Options'}
          icon={faSave}
          buttonText="Save"
          onSave={onSave}
          showDivider
          showLinkUnlink
        />
        {mapOptions.map((mapOption, index) => (
          <Box
            key={mapOption.title}
            className={index === 0 ? commonClasses.firstSettingsContainer : ''}
          >
            <RowItem
              title={mapOption.title}
              description={mapOption.description}
              components={mapOption.components}
            />
            {index !== mapOptions.length - 1 && (
              <Divider className={commonClasses.settingsDivider} />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MapOptions;
